import React, { useState } from 'react';
import loginBgImage from 'assets/img/bg/login-bg.png';
import PropTypes from 'prop-types';
import { Button, Form, FormGroup, Input, Label } from 'reactstrap';
import { Col, Row, Container } from 'reactstrap';
import { useHistory } from 'react-router-dom';
import { useCookies } from 'react-cookie';
import { fetchApi } from '../utils/fetchApi';

const initialFormDataRegister = Object.freeze({
  email: '',
  password: '',
  password_confirmation: '',
  type: 'Peer',
});

const initialFormDataLogin = Object.freeze({
  email: '',
  password: '',
});

const loginRow = {
  height: '100vh',
  justifyContent: 'center',
};

const loginContainer = {
  backgroundImage: `url("${loginBgImage}")`,
  backgroundSize: 'cover',
  backgroundRepeat: 'no-repeat',
  justifyContent: 'center',
  alignItems: 'center',
  backgroundPosition: '50%',
};

function AuthForm(props) {
  let history = useHistory();
  const [formDataRegister, setFormDataRegister] = useState(
    initialFormDataRegister,
  );
  const [formDataLogin, setFormDataLogin] = useState(initialFormDataLogin);
  const [formStatus, setFormStatus] = useState('standby');
  const [cookies, setCookies] = useCookies(['isLoggedIn', 'currentUser']);

  const {
    usernameInputProps,
    passwordInputProps,
    confirmPasswordInputProps,
    children,
  } = props;

  const [isLogin, setIsLogin] = useState(props.authState === STATE_LOGIN);
  const [isRegister, setIsRegister] = useState(
    props.authState === STATE_REGISTER,
  );

  const renderButtonText = e => {
    const { buttonText } = props;
    if (!buttonText && isLogin) {
      return 'Login';
    }

    if (!buttonText && isRegister) {
      return 'Register';
    }
    return buttonText;
  };

  const handleChange = e => {
    if (isLogin === true) {
      setFormDataLogin({
        ...formDataLogin,
        [e.target.name]: e.target.value,
      });
    } else {
      setFormDataRegister({
        ...formDataRegister,
        [e.target.name]: e.target.value,
      });
    }
  };

  async function loginSubmit(e) {
    e.preventDefault();
    setFormStatus('loading');

    const response = await fetchApi(`/login/admin`, 'POST', formDataLogin);
    const json = await response.json();

    console.log(json, 'json');

    if (json.status == 'Success') {
      sessionStorage.setItem('currentUser', JSON.stringify(json.data));
      setCookies('currentUser', JSON.stringify(json.data.id), { path: '/' });
      sessionStorage.setItem('isLoggedIn', true);
      setCookies('isLoggedIn', true, { path: '/' });
      setFormStatus('standby');
      history.push('/');
    } else {
      if (json.errors.email) {
        alert(json.errors.email[0]);
      }
      if (json.errors.password) {
        alert(json.errors.password[0]);
      } else {
        alert(json.errors[0]);
      }
      setFormStatus('standby');
    }
  }

  async function signupSubmit(e) {
    setFormStatus('loading');
    const response = await fetch(
      'https://peerclub.virtualbears.ph/api/register',
      {
        method: 'POST',
        mode: 'cors',
        cache: 'no-cache',
        credentials: 'omit',
        headers: {
          'Access-Control-Allow-Origin': '*',
          'Content-Type': 'application/json',
        },
        redirect: 'follow',
        referrer: 'client',
        body: JSON.stringify(formDataRegister),
      },
    );

    const json = await response.json();
    console.log(json);
    if (json.status == 'Success') {
      history.push('/profile/setup');
      sessionStorage.setItem('currentUser', JSON.stringify(json.data));
      setCookies('currentUser', JSON.stringify(json.data.id), { path: '/' });
      setFormStatus('standby');
    } else {
      if (json.errors.email) {
        alert(json.errors.email[0]);
      }
      if (json.errors.password) {
        alert(json.errors.password[0]);
      } else {
        alert(json.errors[0]);
      }
      setFormStatus('standby');
    }
  }

  return (
    <>
      <Container style={loginContainer} fluid>
        <Container id="login-nav-container" className="pt-4">
          <Row>
            <Col md={6}>
              <a href="/">
                <h2 className="font-weight-bold text-white login-form-nav-header">
                  GIRAR
                </h2>
              </a>
            </Col>
          </Row>
        </Container>
        <Row style={loginRow}>
          <Col md={12} lg={12}>
            <div className="pb-4 login-form-container">
              <Form onSubmit={isLogin ? loginSubmit : signupSubmit}>
                <div className="text-center pb-4">
                  <h2 className="font-weight-bold text-white login-form-header">
                    Login to your account
                  </h2>
                </div>

                <FormGroup>
                  <Label
                    className="text-white login-form-label"
                    htmlFor="email"
                  >
                    Email
                  </Label>
                  <Input
                    {...usernameInputProps}
                    onChange={handleChange}
                    className="login-form-input"
                  />
                </FormGroup>
                <FormGroup>
                  <Label
                    className="text-white  login-form-label"
                    htmlFor="password"
                  >
                    Password
                  </Label>
                  <Input
                    {...passwordInputProps}
                    onChange={handleChange}
                    className="login-form-input"
                  />
                </FormGroup>
                {isRegister && (
                  <FormGroup>
                    <Input
                      {...confirmPasswordInputProps}
                      onChange={handleChange}
                      style={{
                        borderBottom: '1px solid rgba(0,0,0, 0.12)',
                        borderTop: 'none',
                        backgroundColor: 'transparent',
                        borderLeft: 'none',
                        borderRight: 'none',
                      }}
                    />
                  </FormGroup>
                )}

                {formStatus != 'standby' ? (
                  <>
                    {isLogin ? (
                      <Button
                        size="lg"
                        className="border-0 btn-main mt-4"
                        block
                        type="submit"
                        onClick={isLogin ? loginSubmit : signupSubmit}
                      >
                        Logging in...
                      </Button>
                    ) : (
                      <Button
                        size="lg"
                        className="border-0 btn-main mt-4"
                        block
                        type="submit"
                        onClick={isLogin ? loginSubmit : signupSubmit}
                      >
                        Signing up...
                      </Button>
                    )}
                  </>
                ) : (
                  <Button
                    size="lg"
                    type="submit"
                    className="border-0 btn-main mt-4"
                    block
                    onClick={isLogin ? loginSubmit : signupSubmit}
                  >
                    {renderButtonText()}
                  </Button>
                )}

                {children}
              </Form>
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export const STATE_LOGIN = 'LOGIN';
export const STATE_REGISTER = 'REGISTER';
export const STATE_UPDATE_PROFILE = 'UPDATE';

AuthForm.propTypes = {
  authState: PropTypes.oneOf([
    STATE_LOGIN,
    STATE_REGISTER,
    STATE_UPDATE_PROFILE,
  ]).isRequired,
  showLogo: PropTypes.bool,
  usernameLabel: PropTypes.string,
  usernameInputProps: PropTypes.object,
  passwordLabel: PropTypes.string,
  passwordInputProps: PropTypes.object,
  confirmPasswordLabel: PropTypes.string,
  confirmPasswordInputProps: PropTypes.object,
  onLogoClick: PropTypes.func,
};

AuthForm.defaultProps = {
  authState: 'LOGIN',
  showLogo: true,
  usernameLabel: 'Email',
  usernameInputProps: {
    type: 'email',
    name: 'email',
    placeholder: 'Email',
  },
  passwordLabel: 'Password',
  passwordInputProps: {
    type: 'password',
    name: 'password',
    placeholder: 'Password',
  },
  confirmPasswordLabel: 'Confirm Password',
  confirmPasswordInputProps: {
    type: 'password',
    name: 'password_confirmation',
    placeholder: 'Confirm Password',
  },
  onLogoClick: () => {},
};

export default AuthForm;
