export const getCookieValue = name => {
  const nameEQ = name + '=';
  const ca = document.cookie.split(';');
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) === ' ') c = c.substring(1, c.length);
    if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length);
  }
  return null;
};

export const fetchApi = async (path, method = 'GET', params = {}) => {
  const xsrfToken = getCookieValue('XSRF-TOKEN');
  const headers = xsrfToken ? { 'X-XSRF-TOKEN': xsrfToken } : {};
  const requestUrl = `${process.env.REACT_APP_API_URL}${path}`;

  method = method || 'GET';

  if (method === 'PUT') {
    params['_method'] = 'PUT';
    method = 'POST';
  }

  let formData = params;
  if (method === 'POST' && !(params instanceof FormData)) {
    formData = new FormData();
    for (const key in params) {
      formData.append(key, params[key]);
    }
  }

  const bodyData = method === 'GET' ? {} : { body: formData };
  return fetch(requestUrl, {
    ...bodyData,
    method: method,
    mode: 'cors',
    cache: 'no-cache',
    credentials: 'omit',
    headers: {
      ...headers,
      Accept: 'application/json',
      'Access-Control-Allow-Origin': '*',
      'Access-Control-Allow-Credentials': true,
      'Access-Control-Allow-Methods': 'GET,HEAD,OPTIONS,POST,PUT',
      'Access-Control-Allow-Headers':
        'Access-Control-Allow-Headers, Origin,Accept, X-Requested-With, Content-Type, Access-Control-Request-Method, Access-Control-Request-Headers',
    },
    redirect: 'follow',
    referrer: 'client',
  });
};
